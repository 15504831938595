import { defineComponent } from 'vue';
import { useRoute } from 'vue-router/composables';
import DynamicModuleContainer from '@/components/DynamicModuleContainer.vue';
import getDemoData from '@/utils/getDemoData';
export default defineComponent({
    props: {
        moduleName: {
            default() {
                return '';
            }
        },
    },
    components: {
        DynamicModuleContainer
    },
    setup(props) {
        const route = useRoute();
        const no = (route.query.no != undefined && Number(route.query.no)) || 0;
        return {
            data: getDemoData(props.moduleName, no)
        };
    },
});
