import { defineComponent, ref, computed } from 'vue';
import { D3ModuleContainer } from '@bpchart/vue';
export default defineComponent({
    props: {
        data: {
            type: Object
        },
        width: {
            default: () => '100%'
        },
        height: {
            default: () => '100%'
        }
    },
    functional: true,
    render(createElement, context) {
        const width = context.props.width ?? context.data?.attrs?.width;
        const height = context.props.height ?? context.data?.attrs?.height;
        const props = width || height
            ? {
                ...context.props.data.props,
                width,
                height
            }
            : {
                ...context.props.data.emits,
            };
        return createElement(D3ModuleContainer, {
            props,
            on: {}
        });
    }
});
